/* istanbul ignore file */
export const environment = {
    production: true,
    apiBaseUrl: '',
    keycloakAuthServerUrl: 'https://app.nonte.io/auth',
    keycloakClientId: 'slam-ui',
    keycloakIdleTimeoutMinutes: 30,
    apmServerUrl: 'https://app.nonte.io/apm/',
    compressionThreshold: 1024,
};
