<p-toast position="top-center" key="toast" ></p-toast>
<ng-container *transloco="let t">
    <p-dialog 
        [(visible)]="displayModal" 
        [modal]="true" 
        [draggable]="false" 
        [resizable]="false" 
        styleClass="logout-warning" 
        [baseZIndex]="10000" 
        [closable]="false"
        (click)="this.displayModal = false;"
        [header]="t('session.timeout.title')"
    >
        <p class="m-0" [innerHTML]="t('session.timeout.warning')"></p>
    </p-dialog>
</ng-container>

<div class="wrapper" [ngClass]="{'menu-layout-overlay':isOVerlay(),
                                'menu-layout-static':isStatic(),
                                'layout-light':app.lightMenu,
                                'sidebar-inactive-l':menuInactiveDesktop,
                                'sidebar-active-m':menuActiveMobile,
                                'p-ripple-disabled': !app.ripple, 'p-input-filled': app.inputStyle === 'filled'}">
    <app-topbar></app-topbar>

    <div class="sidebar" (click)="onMenuClick()">
        <div class="sidebar-scroll-content">
            <!-- TODO: SLAM-968 - refactor logo to custom component (optional) -->
            <div class="logo">
                <img src="assets/layout/images/nonte-logo-flower-only.png" alt="nonte" >
            </div>
            <!-- TODO: SLAM-968 - refactor profile to custom component -->
            <div class="profile">
                <a id="profile-button" href="#" (click)="toggleProfile($event)">
                    <span class="username">{{ userName }}</span>
                    <span class="fa fa-fw fa-cog"></span>
                </a>
                <ul [@submenu]="profileActive ? 'visible' : 'hidden'">
                    <li>
                        <slam-language-picker></slam-language-picker>
                    </li>
                    <li><a href="#" (click)="logout()"><span class="fa fa-fw fa-sign-out"></span><span>Logout</span></a></li>
                </ul>
            </div>
            <slam-quick-search></slam-quick-search>
            <app-menu></app-menu>
        </div>
    </div>
    <div class="main">
        <div *ngIf="isLoading | async">
            <div class="loading-backdop" [@fadeInOut]></div>
            <p-progressSpinner styleClass="full-screen-spinner" [strokeWidth]="6" [@fadeInOut]></p-progressSpinner>
        </div>
        <router-outlet></router-outlet>
    </div>

    <!-- <app-config></app-config> -->

    <!-- <div class="footer">
        <img src="assets/layout/images/logo-colored.png" alt="" width="32">
        <span class="footer-text">PrimeNG Omega Theme and Layout</span>
    </div> -->
</div>
