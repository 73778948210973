import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiError } from '@models/api-response/slam-api-error.model';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { SlamLoadingService } from './slam-loading.service';
import { SlamSessionService } from './utils/session.service';

@Injectable({
    providedIn: 'root',
})
export class SlamInterceptorService implements HttpInterceptor {
    constructor(public loadingService: SlamLoadingService, private slamSessionService: SlamSessionService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.slamSessionService.recordApiInteraction();
        if (!this.backgroundApiCall(req.url)) {
            this.loadingService.show();
        }
        return next.handle(req).pipe(
            catchError((httpError: HttpErrorResponse) => {
                let apiErrors: ApiError[] = [];
                // Client Side Error
                if (httpError.error instanceof ErrorEvent) {
                    // TODO: SLAM-454: Deal with client side errors?
                    const errMsg = `Error: ${httpError.error.message}`;
                    return throwError(errMsg);
                } else {
                    // Server Side Error
                    if (httpError && httpError.error) {
                        apiErrors = httpError.error;
                    }
                }
                // TODO: SLAM-65: log error?
                return throwError(apiErrors);
            }),
            finalize(() => this.loadingService.hide())
        );
    }

    private backgroundApiCall(url: string): boolean {
        return url === environment.apiBaseUrl + '/api/v1.0/keep-alive';
    }
}
