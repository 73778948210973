// @ts-nocheck

import {Component, OnDestroy} from '@angular/core';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { BreadcrumbService } from './app.breadcrumb.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { PropertyService } from './slam/property.service';
import { Property } from '@models/property/slam-property.model';

export const TOPBAR_HEIGHT_PX = 55;

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnDestroy{

    subscription: Subscription;

    items: MenuItem[];

    public themeStyle: object = {};
    public realmName = "";

    private destroy$ = new Subject<void>();

    constructor(
        public breadcrumbService: BreadcrumbService,
        public app: AppComponent,
        public appMain: AppMainComponent,
        private propertyService: PropertyService,
        ) {
        this.subscription = breadcrumbService.itemsHandler.subscribe(response => {
            this.items = response;
        });
    }

    ngOnInit() {
        this.propertyService.getPropertyByName('realm.theme.color')
            .pipe(takeUntil(this.destroy$))
            .subscribe((property: Property) => {
                if (property) {
                    // since the border will consume towards the inside of the component it is added to, 
                    // we buffer by adding 5 extra pixels to the height so the nonte header bar stays 
                    // the same height when we add the colored bottom border
                    this.themeStyle = { 'height': TOPBAR_HEIGHT_PX + 'px', 'border-bottom' : '5px solid ' + property.value };
                }
            });

        this.propertyService.getPropertyByName('realm.displayName')
            .pipe(takeUntil(this.destroy$))
            .subscribe((property: Property) => {
                    this.realmName = property.value;
            });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.destroy$.next();
        this.destroy$.complete();
    }
}
