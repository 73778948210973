/* istanbul ignore file */

import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppMainComponent } from './app.main.component';

export const routes: Routes = [
    {
        path: '',
        component: AppMainComponent,
        loadChildren: () => import('./slam/slam.module').then((mod) => mod.SlamModule),
    },
];

export const APP_ROUTES: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
});
