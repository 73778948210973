import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { CollectionModel } from '@models/core/slam-collection-model.model';
import { SlamStudy } from '@models/study/slam-study.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SlamStudyService } from '../../study/slam-study.service';

import { SlamContextService } from './slam-context.service';

@Component({
    selector: 'slam-context',
    templateUrl: './slam-context.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlamContextComponent implements OnInit, OnDestroy {
    public studies: SlamStudy[] = [];
    public selectedStudy?: SlamStudy;

    private destroy$ = new Subject<void>();

    constructor(private studyService: SlamStudyService, private contextService: SlamContextService, private changeDetectorRef: ChangeDetectorRef) {
        this.contextService.studyChange$.pipe(takeUntil(this.destroy$)).subscribe((study) => {
            this.selectedStudy = study;
            this.changeDetectorRef.markForCheck();
        });
    }

    ngOnInit(): void {
        this.getStudies();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public updateStudyContext(study: SlamStudy): void {
        this.contextService.setStudy(study);
    }

    private getStudies(): void {
        this.studyService
            .getStudies()
            .pipe(takeUntil(this.destroy$))
            .subscribe((collectionModel: CollectionModel<SlamStudy>) => {
                this.studies = collectionModel._embedded?.studies || [];
                this.setStudyContext();
            });
    }

    private setStudyContext(): void {
        if (this.studies && this.studies.length) {
            this.selectedStudy = this.contextService.initStudyContext(this.studies);
            this.changeDetectorRef.markForCheck();
        }
    }
}
