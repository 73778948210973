<ng-container *transloco="let t">
    <div class="quick-search">
        <span class="fas fa-search"></span>
        <p-autoComplete
            [(ngModel)]="selectedSearchResult"
            [showEmptyMessage]="showEmptyMessage"
            [suggestions]="searchSuggestions"
            (completeMethod)="search($event)"
            [completeOnFocus]="true"
            (onSelect)="select()"
            [placeholder]="t('quick-search.placeholder')"
            appendTo="body"
            field="searchableIdentifier"
            (onClear)="clearSearch()"
        >
            <ng-template let-item pTemplate="item">
                <span
                    *ngIf="item.type === 'SPECIMEN'"
                    class="quick-search-icon fas fa-disease"
                    [title]="t('quick-search.hover-text.specimen')"
                ></span>
                <span *ngIf="item.type === 'SUBJECT'" class="quick-search-icon fas fa-user" [title]="t('quick-search.hover-text.subject')"></span>
                <span *ngIf="item.type === 'TRAY'" class="quick-search-icon fas fa-th" [title]="t('quick-search.hover-text.tray')"></span>
                <span
                    *ngIf="item.type === 'SPECIMEN_LIST'"
                    class="quick-search-icon fas fa-list"
                    [title]="t('quick-search.hover-text.specimen-list')"
                ></span>
                <span>{{ item.searchableIdentifier }}</span>
            </ng-template>
        </p-autoComplete>
    </div>
</ng-container>
