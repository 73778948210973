import { Injectable } from '@angular/core';
import { HttpOptions } from '@models/core/slam-http-options.models';

@Injectable({
    providedIn: 'root',
})
export class SlamHttpOptionsService {
    constructor() {}

    // http.d.ts is very particular that you know which method you are going to call from it at compile time
    // Dynamic method calls are not allowed, so this service will build what you need, but still allow for a more generic HttpOptions interface.
    // Valid types include:
    // observe: 'body' | 'events' | 'response' ;
    // responseType: 'json' | 'text' | 'blob' | 'arraybuffer' ;

    public getOptions(options: HttpOptions): object {
        if (options.observe == null) {
            options.observe = 'body';
        }

        if (options.responseType == null) {
            options.responseType = 'json';
        }

        return {
            headers: options.headers,
            observe: options.observe,
            params: options.params,
            reportProgress: options.reportProgress,
            responseType: options.responseType,
            withCredentials: options.withCredentials,
        };
    }
}
