import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SlamStudy } from '@models/study/slam-study.model';
import { Subject } from 'rxjs';

const STUDY_CONTEXT_KEY = 'context.study';

/* eslint-disable @typescript-eslint/member-ordering */
@Injectable({
    providedIn: 'root',
})
export class SlamContextService {
    private study: SlamStudy;
    public readonly studyChange$ = new Subject<SlamStudy>();

    constructor(private router: Router) {}

    public initStudyContext(userStudies: SlamStudy[]): SlamStudy {
        const currentTabStudy: SlamStudy = JSON.parse(this.getCurrentTabValue(STUDY_CONTEXT_KEY));
        const lastUsedStudy: SlamStudy = JSON.parse(this.getLastUsedValue(STUDY_CONTEXT_KEY));

        let selectedStudy: SlamStudy;
        if (currentTabStudy && this.hasAccessToStudy(userStudies, currentTabStudy)) {
            selectedStudy = currentTabStudy;
        } else if (lastUsedStudy && this.hasAccessToStudy(userStudies, lastUsedStudy)) {
            selectedStudy = lastUsedStudy;
        } else {
            selectedStudy = userStudies[0];
        }
        this.setStudy(selectedStudy);

        return selectedStudy;
    }

    public setStudy(studyIn: SlamStudy): void {
        // if the study has changed, set it and reload the application to have the new context take effect
        if (this.getStudy()?.id !== studyIn.id) {
            this.study = studyIn;
            this.setCurrentTabValue(STUDY_CONTEXT_KEY, JSON.stringify(studyIn));
            this.setLastUsedValue(STUDY_CONTEXT_KEY, JSON.stringify(studyIn));
            this.reloadRoute();
            this.studyChange$.next(studyIn);
        }
    }

    public getStudy(): SlamStudy {
        return (
            (JSON.parse(this.getCurrentTabValue(STUDY_CONTEXT_KEY)) as SlamStudy) ||
            (JSON.parse(this.getLastUsedValue(STUDY_CONTEXT_KEY)) as SlamStudy) ||
            this.study
        );
    }

    private reloadRoute(): void {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl(this.router.url);
    }

    private hasAccessToStudy(userStudies: SlamStudy[], study: SlamStudy): number {
        const studyMatches = userStudies.filter((userStudy) => +userStudy.id === study.id);
        return studyMatches && studyMatches.length;
    }

    private getCurrentTabValue(key: string): string {
        return sessionStorage.getItem(key);
    }

    private setCurrentTabValue(key: string, value: any): void {
        sessionStorage.setItem(key, value);
    }

    private getLastUsedValue(key: string): string {
        return localStorage.getItem(key);
    }

    private setLastUsedValue(key: string, value: any): void {
        localStorage.setItem(key, value);
    }
}
