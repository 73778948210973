import { Injectable } from '@angular/core';
import { Property } from '@models/property/slam-property.model';
import { Observable } from 'rxjs';
import { SlamApiService } from '../utils/slam-api.service';

@Injectable({
    providedIn: 'root',
})
export class PropertyService {
    constructor(private slamApiService: SlamApiService) {}

    public getPropertyByName(name: string): Observable<Property> {
        return this.slamApiService.get<Property>({ href: SlamApiService.apiUrl + '/api/v1.0/properties/search/by-name' }, undefined, {
            params: { name },
        });
    }
}
