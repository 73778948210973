import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ApiLink } from '@models/core/slam-api-link.model';
import { HttpOptions } from '@models/core/slam-http-options.models';
import { Page } from '@models/core/slam-page.model';
import { gzip } from 'pako';
import { LazyLoadEvent } from 'primeng/api';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SlamContextService } from '../slam/core/context/slam-context.service';
import { SlamHttpOptionsService } from './slam-http-options.service';

declare const UriTemplate: any;

@Injectable({
    providedIn: 'root',
})
export class SlamApiService {
    public static apiUrl = environment.apiBaseUrl;

    public static CUSTOM_HEADER_ACCEPT_TIMEZONE = 'Accept-Timezone';
    public static HEADER_CONTENT_TYPE = 'Content-Type';
    public static HEADER_CONTENT_ENCODING = 'Content-Encoding';

    public static PATCH_TEST = 'test';
    public static PATCH_REMOVE = 'remove';
    public static PATCH_ADD = 'add';
    public static PATCH_REPLACE = 'replace';
    public static PATCH_MOVE = 'move';
    public static PATCH_COPY = 'copy';

    // These sort values come from PrimeNG LazyLoadEvent documentation
    public static SORT_DESC = -1;
    public static SORT_ASC = 1;

    constructor(private http: HttpClient, private optionsService: SlamHttpOptionsService, private contextService: SlamContextService) {}

    public get<T>(urlIn: ApiLink, paramArgs?: object, options?: HttpOptions): Observable<T> {
        if (!urlIn || !urlIn.href) {
            throw Error('Error - GET method was not given a url');
        }

        const url: ApiLink = this.fillPathParams(urlIn, paramArgs);

        if (!options) {
            options = {};
        }

        this.setDefaultHeaders(options);
        return this.http.get<T>(url.href, this.optionsService.getOptions(options));
    }

    public post<T>(urlIn: ApiLink, requestBody: any, paramArgs?: object, options?: HttpOptions): Observable<T> {
        if (!urlIn || !urlIn.href) {
            throw Error('Error - POST method was not given a url');
        }

        const url: ApiLink = this.fillPathParams(urlIn, paramArgs);
        let body = this.extractValue(requestBody);

        if (!options) {
            options = {};
        }
        this.setDefaultHeaders(options);

        body = this.encode(body, options);

        return this.http.post<T>(url.href, body, this.optionsService.getOptions(options));
    }

    public put<T>(urlIn: ApiLink, control: AbstractControl | object, paramArgs?: object, options?: HttpOptions): Observable<T> {
        if (!urlIn || !urlIn.href) {
            throw Error('Error - PUT method was not given a url');
        }

        const url: ApiLink = this.fillPathParams(urlIn, paramArgs);

        const body = this.extractValue(control);

        if (!options) {
            options = {};
        }
        this.setDefaultHeaders(options);

        if (!body) {
            throw Error('Error - PUT method was not given a body');
        }
        return this.http.put<T>(url.href, body, this.optionsService.getOptions(options));
    }

    public delete<T>(urlIn: ApiLink, paramArgs?: object, options?: HttpOptions): Observable<T> {
        if (!urlIn || !urlIn.href) {
            throw Error('Error - DELETE method was not given a url');
        }

        const url: ApiLink = this.fillPathParams(urlIn, paramArgs);

        if (!options) {
            options = {};
        }
        this.setDefaultHeaders(options);

        return this.http.delete<T>(url.href, this.optionsService.getOptions(options));
    }

    public patch<T>(urlIn: ApiLink, op: string, control: AbstractControl | object, paramArgs?: object, options?: HttpOptions): Observable<T> {
        if (!urlIn || !urlIn.href) {
            throw Error('Error - PATCH method was not given a url');
        }
        if (
            !op ||
            (op !== SlamApiService.PATCH_TEST &&
                op !== SlamApiService.PATCH_REMOVE &&
                op !== SlamApiService.PATCH_ADD &&
                op !== SlamApiService.PATCH_REPLACE &&
                op !== SlamApiService.PATCH_MOVE &&
                op !== SlamApiService.PATCH_COPY)
        ) {
            throw Error('Error - PATCH method was not given a operation');
        }

        const url: ApiLink = this.fillPathParams(urlIn, paramArgs);
        const body = { op, value: this.extractValue(control) };

        if (!options) {
            options = {};
        }
        this.setDefaultHeaders(options);

        if (!body.value) {
            throw Error('Error - PATCH method was not given a body');
        }

        return this.http.patch<T>(url.href, this.encode(body, options), this.optionsService.getOptions(options));
    }

    public springDataRestPatch<T>(urlIn: ApiLink, body: object, paramArgs?: object, options?: HttpOptions): Observable<T> {
        if (!urlIn || !urlIn.href) {
            throw Error('Error - PATCH method was not given a url');
        }

        if (!body) {
            throw Error('Error - PATCH method was not given a body');
        }

        const url: ApiLink = this.fillPathParams(urlIn, paramArgs);

        if (!options) {
            options = {};
        }
        this.setDefaultHeaders(options);

        return this.http.patch<T>(url.href, body, this.optionsService.getOptions(options));
    }

    public buildPageParams(event: LazyLoadEvent): Page {
        let page: Page = {};
        if (event && (event.first || event.first === 0) && event.rows) {
            page = { page: event.first / event.rows, size: event.rows };
        }

        if (event && event.sortField && (event.sortOrder === SlamApiService.SORT_DESC || event.sortOrder === SlamApiService.SORT_ASC)) {
            page.sort = event.sortField + ',' + (event.sortOrder === SlamApiService.SORT_ASC ? 'asc' : 'desc');
        }

        return page;
    }

    public fillPathParams(url: ApiLink, paramArgs?: object): ApiLink {
        let params = {};
        if (paramArgs) {
            params = { ...paramArgs };
        }
        const urlClone = { ...url };
        // eslint-disable-next-line @typescript-eslint/dot-notation
        params['studyId'] = this.contextService.getStudy()?.id;
        urlClone.href = UriTemplate(urlClone.href).fill(params);
        return urlClone;
    }

    private extractValue(requestBody?: any) {
        let body: object | string | undefined;
        if (requestBody) {
            if (requestBody instanceof AbstractControl) {
                body = requestBody.value;
            } else {
                body = requestBody;
            }
        }

        return body;
    }

    private setDefaultHeaders(options: HttpOptions): void {
        if (!options.headers) {
            options.headers = new HttpHeaders();
        }

        if (!options.headers.has(SlamApiService.HEADER_CONTENT_TYPE)) {
            options.headers = options.headers.set(SlamApiService.HEADER_CONTENT_TYPE, 'application/json');
        }

        if (!options.headers.has(SlamApiService.CUSTOM_HEADER_ACCEPT_TIMEZONE)) {
            options.headers = options.headers.set(SlamApiService.CUSTOM_HEADER_ACCEPT_TIMEZONE, Intl.DateTimeFormat().resolvedOptions().timeZone);
        }
    }

    private encode(body: object | string, options: HttpOptions): object | string {
        if (options.headers?.get(SlamApiService.HEADER_CONTENT_ENCODING) === 'gzip') {
            const strBody = JSON.stringify(body);
            if (strBody.length > environment.compressionThreshold) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return gzip(JSON.stringify(body)).buffer;
            } else {
                options.headers = options.headers.delete(SlamApiService.HEADER_CONTENT_ENCODING);
            }
        }
        return body;
    }
}
