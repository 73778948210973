import { Injectable } from '@angular/core';
import { Translation, TranslocoLoader, TRANSLOCO_LOADER, flatten } from '@ngneat/transloco';
import { merge } from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import packageJson from 'package.json';
import { SlamCustomTranslationService } from './slam/custom-translation/slam-custom-translation.service';
import { SlamApiService } from './utils/slam-api.service';

@Injectable({ providedIn: 'root' })
export class HttpLoader implements TranslocoLoader {
    constructor(private slamApiService: SlamApiService, private customTranslationService: SlamCustomTranslationService) {}

    getTranslation(langPath: string): Observable<Translation> {
        return forkJoin({
            realmTranslation: this.customTranslationService.getTranslation(langPath),
            defaultTranslation: this.slamApiService.get<Translation>({ href: `assets/i18n/${langPath}.json?v=${packageJson.version}` }),
        }).pipe(
            map((translations) => {
                // Flatten the results so when changing languages the custom ones take precedence.
                return flatten(merge(translations.defaultTranslation, translations.realmTranslation));
            })
        );
    }
}

export const translocoLoader = {
    provide: TRANSLOCO_LOADER,
    useClass: HttpLoader,
};
