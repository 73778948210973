<ng-container *transloco="let t">
    <strong>{{ t('study.label') }}: </strong>
    <p-dropdown
        dataKey="id"
        [options]="studies"
        optionLabel="name"
        [(ngModel)]="selectedStudy"
        (onChange)="updateStudyContext($event.value)"
        [placeholder]="t('study.select-study')"
    >
    </p-dropdown>
</ng-container>
