import { Injectable } from '@angular/core';
import { ApiLinks } from '@models/core/slam-api-links.model';
import { UserInfo } from '@models/user-info/slam-user-info.model';
import { take } from 'rxjs/operators';
import { Observable, ReplaySubject } from 'rxjs';
import { SlamApiService } from 'src/app/utils/slam-api.service';

@Injectable({
    providedIn: 'root',
})
export class SlamUserInfoService {
    public readonly userInfoChange$ = new ReplaySubject<UserInfo>(1);

    private readonly USER_INFO_PATH = '/api/v1.0/user-info/metadata';

    private readonly _endpoints$ = new ReplaySubject<ApiLinks>(1);

    private userInfo?: UserInfo;

    constructor(private slamApiService: SlamApiService) {
        this.retrieveEndPoints();
        this.retrieveUserInfo();
    }

    public setLatestViewedRelease(latestViewedRelease: string): void {
        const userInfoToSave = { ...this.userInfo, latestViewedRelease } as UserInfo;
        this.saveUserInfo(userInfoToSave);
    }

    public setPreferredLanguage(preferredLanguage: string): void {
        const userInfoToSave = { ...this.userInfo, preferredLanguage } as UserInfo;
        this.saveUserInfo(userInfoToSave);
    }

    private saveUserInfo(userInfoToSave: UserInfo): void {
        this.endpoints()
            .pipe(take(1))
            .subscribe((endpoints) => {
                this.slamApiService
                    .post<UserInfo>(endpoints.save, userInfoToSave)
                    .pipe(take(1))
                    .subscribe((userInfo) => {
                        this.userInfo = userInfo;
                        this.userInfoChange$.next(this.userInfo);
                    });
            });
    }

    private retrieveUserInfo(): void {
        this.endpoints()
            .pipe(take(1))
            .subscribe((endpoints) => {
                this.slamApiService
                    .get<UserInfo>(endpoints.get)
                    .pipe(take(1))
                    .subscribe((userInfo) => {
                        this.userInfo = userInfo;
                        this.userInfoChange$.next(this.userInfo);
                    });
            });
    }

    private retrieveEndPoints() {
        this.slamApiService
            .get<UserInfo>({ href: this.USER_INFO_PATH })
            .pipe(take(1))
            .subscribe((endPoints) => {
                this._endpoints$.next(endPoints._links);
            });
    }

    public endpoints(): Observable<ApiLinks> {
        return this._endpoints$.asObservable();
    }
}
