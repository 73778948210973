import { Injectable } from '@angular/core';
import { ApiLink } from '@models/core/slam-api-link.model';
import { CollectionModel } from '@models/core/slam-collection-model.model';
import { Page } from '@models/core/slam-page.model';
import { PagedModel } from '@models/core/slam-paged-model.model';
import { FormCategory } from '@models/form-io/slam-form-io-form-category.model';
import { FormIoFormResult } from '@models/form-io/slam-form-io-form-result.model';
import { FormIoFormType } from '@models/form-io/slam-form-io-form-type.model';
import { SlamFormIoForm } from '@models/form-io/slam-form-io-form.model';
import { Specimen } from '@models/specimen/slam-specimen.model';
import { Observable, Subject } from 'rxjs';
import { SlamApiService } from 'src/app/utils/slam-api.service';
import { environment } from 'src/environments/environment';
import { FormLayoutType } from 'src/app/slam/form-io/slam-form-io-form-enums';
import { SlamSubject } from './../models/subject/slam-subject.model';

@Injectable({
    providedIn: 'root',
})
export class SlamFormService {
    static readonly FORM_IO_API_URL: string = '/api/v1.0/study/{studyId}/forms';

    public readonly formResultCollectionChange$ = new Subject();

    private url: ApiLink;

    constructor(private slamApiService: SlamApiService) {
        this.url = {
            href: environment.apiBaseUrl + SlamFormService.FORM_IO_API_URL,
        };
    }

    /**
     * Returns a collection of all FormIoForm, whether they have an active version or not
     */
    public getAllForms(): Observable<PagedModel<SlamFormIoForm>> {
        const url = { href: this.url.href + '/builder' };
        return this.slamApiService.get<PagedModel<SlamFormIoForm>>(url);
    }

    /**
     * Returns a collection of all FormIoForm, whether they have an active version or not
     */
    public getAuditorForms(): Observable<PagedModel<SlamFormIoForm>> {
        const url = { href: this.url.href + '/auditor' };
        return this.slamApiService.get<PagedModel<SlamFormIoForm>>(url);
    }

    public getFormTypes(): Observable<FormIoFormType[]> {
        const url = {
            href: environment.apiBaseUrl + '/api/v1.0/form-io/forms/formtypes',
        };
        return this.slamApiService.get<FormIoFormType[]>(url);
    }

    public getFormCategories(): Observable<CollectionModel<FormCategory>> {
        const url = {
            href: environment.apiBaseUrl + '/api/v1.0/form-categories',
        };
        return this.slamApiService.get<CollectionModel<FormCategory>>(url, null, { params: { sort: 'name' } });
    }

    public getFormDtoByFormIdAndVersion(formId: number, version: number): Observable<SlamFormIoForm> {
        const url = { href: this.url.href + '/{formId}/form-versions/{version}/builder' };

        return this.slamApiService.get<SlamFormIoForm>(url, { formId, version });
    }

    public getFormsByType(
        constant: string,
        specimen?: Specimen,
        subject?: SlamSubject,
        traySpecimenTypeId?: number
    ): Observable<PagedModel<SlamFormIoForm>> {
        let specimenTypeId: number;
        if (specimen) {
            specimenTypeId = specimen.specimenType?.id;
        } else if (traySpecimenTypeId) {
            specimenTypeId = traySpecimenTypeId;
        }
        const url = { href: this.url.href + '{?constant,specimenId,subjectId,specimenTypeId}' };
        return this.slamApiService.get<PagedModel<SlamFormIoForm>>(url, {
            constant,
            specimenId: specimen?.id,
            subjectId: subject?.id,
            specimenTypeId,
        });
    }

    public getFormsByFormTypeConstant(formTypeConstant: string): Observable<PagedModel<SlamFormIoForm>> {
        const url = { href: environment.apiBaseUrl + '/api/v1.0/forms{?formTypeConstant}' };
        return this.slamApiService.get<PagedModel<SlamFormIoForm>>(url, { formTypeConstant });
    }

    /**
     * Returns the rendered JSON structure of a FormIoForm, ready for display by FormIo
     */
    public getFormByConstant(constant: string): Observable<SlamFormIoForm> {
        const url = { href: this.url.href + '/rendered{?constant}' };
        return this.slamApiService.get<SlamFormIoForm>(url, { constant });
    }

    /**
     * Returns the rendered JSON structure of a FormIoForm, ready for display by FormIo
     */
    public getRenderedForm(formId: number, formVersionId?: number, layoutType?: FormLayoutType): Observable<SlamFormIoForm> {
        const url = { href: this.url.href + '/{formId}/rendered{?formVersionId,layoutType}' };
        return this.slamApiService.get<SlamFormIoForm>(url, { formId, formVersionId, layoutType });
    }

    public getFormResults(formId: number, specimen: Specimen, subject: SlamSubject, page?: Page): Observable<PagedModel<FormIoFormResult>> {
        const url = { href: this.url.href + '/{formId}/results{?specimenId,subjectId}{&page,size,sort}' };
        return this.slamApiService.get<PagedModel<FormIoFormResult>>(url, {
            formId,
            specimenId: specimen?.id,
            subjectId: subject?.id,
            ...page,
        });
    }

    public getFormResultsForInstance(formInstanceId: number): Observable<FormIoFormResult[]> {
        const url = {
            href: this.url.href + '/instance/{formInstanceId}/results',
        };
        return this.slamApiService.get<FormIoFormResult[]>(url, {
            formInstanceId,
        });
    }
}
