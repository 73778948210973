<div class="topbar" [ngStyle]="themeStyle">
    <div id="topbar-left">
        <a id="omega-menu-button" href="#" (click)="appMain.toggleMenu($event)">
            <span class="pi pi-bars"></span>
        </a>
        <!-- TODO: SLAM-968 - refactor title to custom component -->
        <span class="topbar-title">
            <div><img src="assets/layout/images/nonte-white-text-only.png" alt="nonte"></div>
            <div *ngIf="realmName" class="title-pipe">–</div>
            <div *ngIf="realmName" class="title-realm-name">{{ realmName }}</div>
        </span>
        <!-- <ng-template ngFor let-item let-last="last" [ngForOf]="items"> -->
        <!-- </ng-template> -->
    </div>

    <a id="options-menu-button" href="#" (click)="appMain.toggleTopMenu($event)">
        <span class="pi pi-ellipsis-h"></span>
    </a>

    <div id="topbar-icons" class="animated" [ngClass]="{'topbar-icons-visible flipInX':appMain.topMenuActive,'flipOutX':appMain.topMenuLeaving}">
        <slam-context></slam-context>
        <slam-release-notes></slam-release-notes>
    </div>
</div>
