// Form Constants
export const SPECIMEN_FORM = 'SPECIMEN';
export const SUBJECT_FORM = 'SUBJECT';

// FormType Constants
export const CRUD = 'CRUD';
export const FORM_TYPE_SPECIMEN = 'SPECIMEN';
export const FORM_TYPE_CHILD_SPECIMEN = 'CHILD_SPECIMEN';
export const FORM_TYPE_ALIQUOT_SPECIMEN = 'ALIQUOT_SPECIMEN';
export const FORM_TYPE_SIBLING_SPECIMEN = 'SIBLING_SPECIMEN';
export const FORM_TYPE_SPECIMEN_FORM_RESULTS = 'SPECIMEN_FORM_RESULTS';
export const FORM_TYPE_SUBJECT = 'SUBJECT';
export const FORM_TYPE_SUBJECT_FORM_RESULTS = 'SUBJECT_FORM_RESULTS';
