import { Component, Injectable } from '@angular/core';
import { isEqualWith, isNull } from 'lodash';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SlamPendingChangesService {
    public pendingChangesChange: Subject<void> = new Subject<void>();

    private componentsWithPendingChanges: Set<Component> = new Set();

    public addPendingChanges(component: Component): void {
        this.componentsWithPendingChanges.add(component);
        this.pendingChangesChange.next();
    }

    public removePendingChanges(component: Component): void {
        this.componentsWithPendingChanges.delete(component);
        this.pendingChangesChange.next();
    }

    // method compares inital field values to current values, and treats null and empty string values as the same
    // code taken from https://stackoverflow.com/questions/58718321/compare-js-objects-with-values-null-and-empty-string/58718363#58718363
    public hasChanged(previousValue: any, currentValue: any): boolean {
        return !isEqualWith(previousValue, currentValue, (a, b) => {
            if ((isNull(a) || a === '') && (isNull(b) || b === '')) {
                return true;
            } else if ((typeof a === 'string' && typeof b === 'number') || (typeof a === 'number' && typeof b === 'string')) {
                // If one of the inputs is a number and the other is a string, compare them without type checking.
                // eslint-disable-next-line eqeqeq
                if (a == b) {
                    return true;
                }
            }
        });
    }

    public clearPendingChanges(): void {
        this.componentsWithPendingChanges.clear();
        this.pendingChangesChange.next();
    }

    public pendingChanges(): boolean {
        return !!this.componentsWithPendingChanges.size;
    }
}
