/* istanbul ignore file */

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormioAppConfig } from '@formio/angular';
import { TranslocoConfig, TranslocoModule, TRANSLOCO_CONFIG } from '@ngneat/transloco';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { MessageService } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ToastModule } from 'primeng/toast';

import { AutoCompleteModule } from 'primeng/autocomplete';
import { DialogModule } from 'primeng/dialog';

import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { BadgeModule } from 'primeng/badge';
import { SidebarModule } from 'primeng/sidebar';
import { environment } from '../environments/environment';
import { MenuService } from './app.menu.service';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { AppTopBarComponent } from './app.topbar.component';
import { BreadcrumbService } from './app.breadcrumb.service';
import { AppMenuComponent } from './app.menu.component';
import { AppMenuitemComponent } from './app.menuitem.component';
import { APP_ROUTES } from './app.routes';
import { EnvConfigService } from './env-config.service';
import { SlamInterceptorService } from './slam-interceptor.service';
import { SlamContextComponent } from './slam/core/context/slam-context.component';
import { SlamFormioAppConfig } from './slam/form-io/config/form-io.config';
import { SlamQuickSearchComponent } from './slam/quick-search/slam-quick-search.component';
import { SlamReleaseNotesComponent } from './slam/release-notes/slam-release-notes.component';
import { translocoLoader } from './transloco.loader';
import { initializer } from './utils/app-init';
import { SlamLanguagePickerComponent } from './slam/core/language-picker/slam-language-picker.component';
import { FeatureFlagDirective } from './slam/core/feature-flag.directive';

@NgModule({
    imports: [
        // Core Angular Imports
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,

        // Keycloak Import (To allow for redirecting an unauthenticated user)
        KeycloakAngularModule,

        // Transloco Import
        TranslocoModule,

        // PrimeNG Import, needed for the core app
        AutoCompleteModule,
        ProgressSpinnerModule,
        ScrollPanelModule,
        ToastModule,
        SidebarModule,
        TooltipModule,
        ButtonModule,
        BadgeModule,
        DialogModule,
        DropdownModule,
        FormsModule,

        // Import custom Modules as defined in app.routes.ts
        APP_ROUTES,
    ],
    declarations: [
        AppComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppMainComponent,
        AppTopBarComponent,
        FeatureFlagDirective,
        SlamContextComponent,
        SlamLanguagePickerComponent,
        SlamQuickSearchComponent,
        SlamReleaseNotesComponent,
    ],
    providers: [
        MenuService,
        MessageService,
        KeycloakService,
        EnvConfigService,
        BreadcrumbService,
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy,
        },
        translocoLoader,
        {
            provide: TRANSLOCO_CONFIG,
            useValue: {
                availableLangs: ['en', 'fr'],
                defaultLang: 'en',
                fallbackLang: 'en',
                missingHandler: {
                    // It will use the first language set in the `fallbackLang` property
                    useFallbackTranslation: true,
                },
                prodMode: environment.production,
            } as TranslocoConfig,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SlamInterceptorService,
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializer,
            multi: true,
            deps: [KeycloakService],
        },
        {
            provide: FormioAppConfig,
            useValue: SlamFormioAppConfig,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (envConfigService: EnvConfigService) => () => envConfigService.load().toPromise(),
            deps: [EnvConfigService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
