import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { from } from 'rxjs';

import { environment } from '../../environments/environment';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function initializer(keycloak: KeycloakService): () => Promise<any> {
    // https://github.com/mauriciovigolo/keycloak-angular/issues/149#issuecomment-661885981
    from(keycloak.keycloakEvents$).subscribe((event) => {
        if (event.type === KeycloakEventType.OnAuthSuccess) {
            sessionStorage.clear();
        }
    });

    return (): Promise<any> =>
        keycloak.init({
            config: {
                url: environment.keycloakAuthServerUrl,
                realm: getRealmName(),
                clientId: environment.keycloakClientId,
            },
            initOptions: {
                onLoad: 'login-required',
                checkLoginIframe: false,
            },
            enableBearerInterceptor: true,
            bearerExcludedUrls: [],
        });
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function getRealmName(): string {
    // Matches REALM_NAME.whatever (in location.host)
    const regex = /^(\w+)\..*$/;
    const matches = regex.exec(window.location.host);

    if (matches) {
        return matches[1].toString();
    } else {
        throw new Error('NO REALM');
    }
}
