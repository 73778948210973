import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { QuickSearchResult } from '@models/quick-search/slam-quick-search-result.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SlamContextService } from '../core/context/slam-context.service';
import { SlamQuickSearchService } from './slam-quick-search.service';

@Component({
    selector: 'slam-quick-search',
    templateUrl: './slam-quick-search.component.html',
    styleUrls: ['./slam-quick-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlamQuickSearchComponent implements OnDestroy {
    public searchSuggestions: QuickSearchResult[] = [];
    public selectedSearchResult?: QuickSearchResult;
    public showEmptyMessage = false;

    private destroy$ = new Subject<void>();

    constructor(
        private quickSearchService: SlamQuickSearchService,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private slamContextService: SlamContextService
    ) {}

    select(): void {
        if (this.selectedSearchResult) {
            let path = '';
            let extras: NavigationExtras = {};
            switch (this.selectedSearchResult.type) {
                case 'SPECIMEN':
                    path = '/specimen/view';
                    extras = { queryParams: { specimenId: this.selectedSearchResult.id } };
                    break;
                case 'SUBJECT':
                    path = '/subject/view';
                    extras = { queryParams: { subjectId: this.selectedSearchResult.id } };
                    break;
                case 'TRAY':
                    path = '/trays/view';
                    extras = { queryParams: { trayId: this.selectedSearchResult.id } };
                    break;
                case 'SPECIMEN_LIST':
                    path = '/specimen-list/view';
                    extras = { queryParams: { specimenListId: this.selectedSearchResult.id } };
                    break;
                default:
                    throw new Error('Invalid quick search result type');
            }

            if (path.length > 0) {
                this.clearSearch();
                this.router.navigate([path], extras).then(() => {
                    if (this.selectedSearchResult?.study) {
                        this.slamContextService.setStudy(this.selectedSearchResult.study);
                    }
                });
            }
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public search(event: any): void {
        if (event.query.length >= 2) {
            this.showEmptyMessage = true;
            this.quickSearchService
                .getQuickSearchResult(event.query)
                .pipe(takeUntil(this.destroy$))
                .subscribe((results) => {
                    this.searchSuggestions = results;
                    this.changeDetectorRef.markForCheck();
                });
        } else {
            this.searchSuggestions = [];
            this.showEmptyMessage = false;
        }
    }

    public clearSearch(): void {
        this.searchSuggestions = [];
        this.changeDetectorRef.markForCheck();
    }
}
