import { Release } from '../models/release-notes/slam-release.model';

export const RELEASES: Release[] = [
    {
        title: '2024-08 (v0.16.0)',
        releaseNotes: [
            {
                title: 'Export',
                descriptions: [
                    "A large data dump of a study's root specimens, all their form results and child form results can now be exported into a spreadsheet.",
                ],
            },
            {
                title: 'Locations, Trays',
                descriptions: ['Searching for a location on the tray form and a parent location on the Location form now functions properly.'],
            },
            {
                title: 'Specimen Lists, Specimen Search',
                descriptions: [
                    'Error is now displayed when attempting to save a form result against a specimen that does not have a matching specimen type.',
                ],
            },
        ],
    },
    {
        title: '2024-05 (v0.15.0)',
        releaseNotes: [
            {
                title: 'Form Builder',
                descriptions: ['Form Builder Form Controls with dots can now have translation overrides and no longer block form save.'],
            },
            {
                title: 'Search',
                descriptions: [
                    'Fixed Rules within a nested Ruleset missing their operators on the Searches.',
                    'Field options in search queries are now sorted by name.',
                ],
            },
            {
                title: 'Locations',
                descriptions: ['Parent Location select options update correctly after a new location is added.'],
            },
            {
                title: 'Specimen Table',
                descriptions: ['Date fields and Day of Study now have columns in the specimen table.'],
            },
            {
                title: 'Trays',
                descriptions: ['Trays now support multiple specimen types.'],
            },
        ],
    },
    {
        title: '2024-05 (v0.14.0)',
        releaseNotes: [
            {
                title: 'Form Builder',
                descriptions: ['Fixed a bug that was causing an error in the console for parsing translation labels that did have "values".'],
            },
            {
                title: 'Imports',
                descriptions: ['Importer now handles periods that are within the external API keys on a spreadsheet.'],
            },
        ],
    },
    {
        title: '2024-03 (v0.13.0)',
        releaseNotes: [
            {
                title: 'Form Builder',
                descriptions: [
                    'Form Result forms can now be nested inside nested Specimen forms.',
                    'Added more translation overrides for additional form builder components.',
                ],
            },
            {
                title: 'Imports',
                descriptions: ['Single row spreadsheets can now be imported into single type forms.', 'Trays can now be imported from spreadsheets.'],
            },
        ],
    },
    {
        title: '2023-12 (v0.12.0)',
        releaseNotes: [
            {
                title: 'Trays',
                descriptions: [
                    'Fixed a bug where the tray picker was not ignoring previously deleted specimens in a tray slot it was attempting to insert a new specimen into.',
                ],
            },
            {
                title: 'Imports',
                descriptions: ['Fixed a bug where trailing whitespace on imported spreadsheet values would block form submission.'],
            },
        ],
    },
    {
        title: '2023-11 (v0.11.0)',
        releaseNotes: [
            {
                title: 'Specimens',
                descriptions: ['Added the ability to search Specimen via details about their Subject.'],
            },
            {
                title: 'Identifier Sequences',
                descriptions: ['Added the ability for more customized Identifier Sequence patterns.'],
            },
            {
                title: 'Imports',
                descriptions: ['Fixed an issue where Specimen created via the Specimen Import feature were not searchable.'],
            },
            {
                title: 'Searches',
                descriptions: ['Reindexing searches no longer gives up on the first error. Will skip record and carry on.'],
            },
        ],
    },
    {
        title: '2023-10 (v0.10.1)',
        releaseNotes: [
            {
                title: 'Subjects, Trays',
                descriptions: ['Fixed pages not loading content when a Specimen referenced a deleted Subject.'],
            },
            {
                title: 'Searches',
                descriptions: ['Fixed empty string values not being considered null in searches.'],
            },
        ],
    },
    {
        title: '2023-09 (v0.10.0)',
        releaseNotes: [
            {
                title: 'Specimens',
                descriptions: [
                    'Specimens in the relationship graph can now be clicked to view that specimen.',
                    'The specimen relationship graph can now be zoomed in or out.',
                    'Deleted specimens can now be shown and hidden in the relationship graph',
                    "The pool column in the specimen table now links to the specimen's pool.",
                    "Fixed a bug where a pool's constituent specimens failed to load.",
                ],
            },
            {
                title: 'CRUD Forms',
                descriptions: ['Fixed a bug where CRUD forms were not updating properly.', "Fixed an issue where cohort CRUD table wouldn't load."],
            },
            {
                title: 'Imports',
                descriptions: [
                    'Subjects can now be imported from a spreadsheet.',
                    'Form Result Import now supports fields nested within layout components.',
                ],
            },
            {
                title: 'Translations',
                descriptions: [
                    'French translations are now accessible from the sidebar.',
                    'Forms can now be built with translations to support different languages.',
                ],
            },
        ],
    },
    {
        title: '2023-09 (v0.9.0)',
        releaseNotes: [
            {
                title: 'Form Result Search',
                descriptions: ['Form results are now searchable.', 'Form result searches can be exported into spreadsheets.'],
            },
            {
                title: 'Form Builder',
                descriptions: ['Form builder now blocks form save when errors are present in the builder.'],
            },
            {
                title: 'Crud',
                descriptions: [
                    'Current Page no longer freezes when switching studies on a Crud form.',
                    'A new Crud was added for managing availability statuses in the admin section.',
                    'Unit description now has validation implemented.',
                ],
            },
            {
                title: 'Specimens',
                descriptions: [
                    'Fixed an error that would happen when viewing specimens whe there were no specimen lists present.',
                    'Fixed the Units dropdown on the Specimen Details form being empty.',
                    'Fixed Pool`s constituent specimens not loading on specimen view.',
                ],
            },
            {
                title: 'Grid Forms',
                descriptions: ['Unnecessary fields no longer show in the Set All section of grid forms.'],
            },
        ],
    },
    {
        title: '2023-05 (v0.8.0)',
        releaseNotes: [
            {
                title: 'Form Result Importer',
                descriptions: ['A new import page added to allow for the import of form results onto a selected form.'],
            },
            {
                title: 'Specimens',
                descriptions: ['Parent, Source and Root Specimens tray information are now available within Form Builder.'],
            },
            {
                title: 'Searches',
                descriptions: ['The text fields on the searches have been made wider.'],
            },
        ],
    },
    {
        title: '2023-03 (v0.7.0)',
        releaseNotes: [
            {
                title: 'Grids',
                descriptions: ['Calculated values of specimen form fields can now be set to display tray information.'],
            },
            {
                title: 'Trays',
                descriptions: [
                    'Manage Tray Contents now displays Location and Full status in the table, additionally full status is displayed when viewing a tray.',
                ],
            },
            {
                title: 'Units',
                descriptions: ['Added Units CRUD form to Admin section.'],
            },
            {
                title: 'Searches',
                descriptions: [
                    'Specimens created in forms are now indexed for searching and should appear in search results without manually reindexing.',
                ],
            },
        ],
    },
    {
        title: '2023-02 (v0.6.1)',
        releaseNotes: [
            {
                title: 'Grids',
                descriptions: ['Improved rendering performance of tables in forms.'],
            },
        ],
    },
    {
        title: '2023-01 (v0.6.0)',
        releaseNotes: [
            {
                title: 'General',
                descriptions: ['Increased default page size on tables to 50.', 'Added 100 rows as a page size option on tables.'],
            },
            {
                title: 'Subjects',
                descriptions: [
                    "Fixed issue where Subject's Specimens was not updating when switching between Subjects.",
                    'Added the ability to export Subject Search results.',
                ],
            },
            {
                title: 'Specimen Types',
                descriptions: ['Added an abbreviation field.'],
            },
        ],
    },
    {
        title: '2022-12 (v0.5.0)',
        releaseNotes: [
            {
                title: 'General',
                descriptions: [
                    'Pending changes warning no longer shows on timeout.',
                    `Improved warning logic so the user isn't warned unnecessarily or in the wrong cases.`,
                ],
            },
            {
                title: 'Form Builder',
                descriptions: [
                    'Updated label for  delete version button.',
                    'Fixed typo-ed message key : form-io.error.invalidStudy => form-io.error.invalid-study.',
                    'Reordered the form version edit menu so create draft comes before retire and added icons for the edit menu to help scan and find the item the user wants.',
                    'Improved the Audit table in the Form Builder to display more values.',
                    'Added the ability to place a year picker form field in form builder.',
                    'User now has the ability to navigate to the form builder of a selected form from the form picker.',
                ],
            },
            {
                title: 'Exception Handling',
                descriptions: ['Fixed error messages of "must not be null" not including more details.'],
            },
            {
                title: 'Searches',
                descriptions: [
                    'Added a database and elasticsearch count to specimen, subject, and form search.',
                    'Users can now search for specimens by pool.',
                ],
            },
            {
                title: 'Specimens',
                descriptions: [`Users can now add a specimen to another specimen list from the specimen list view.`],
            },
        ],
    },
    {
        title: '2022-11 (v0.4.2)',
        releaseNotes: [
            {
                title: 'Forms',
                descriptions: [
                    'Fixed calculated form values not being overridable by the user.',
                    'Fixed viewing, editing, and importing of values into Single-Use forms.',
                ],
            },
            {
                title: 'Searches',
                descriptions: ['Fixed not properly parsing some form configurations. This affected "Display all form fields" on searches.'],
            },
        ],
    },
    {
        title: '2022-11 (v0.4.1)',
        releaseNotes: [
            {
                title: 'Tray',
                descriptions: [`Fixed not being able to set a Tray's Location.`],
            },
            {
                title: 'Form Versions',
                descriptions: [
                    `Fixed the Form Version dropdown not always appearing when selecting a form for users who have the Form Builder User role.`,
                ],
            },
        ],
    },
    {
        title: '2022-10 (v0.4.0)',
        releaseNotes: [
            {
                title: 'Form Versions',
                descriptions: [
                    `Added versioning for form configurations.`,
                    `Added a publication status attribute to form versions, to support the creation of 'Draft', 'Active' and 'Retired' form states.`,
                    `Added a preview mode to view 'Draft' form versions in context for users who have the Form Builder User role.`,
                    `Added validation to reduce the likelihood of accidental form misconfigurations.`,
                ],
            },
            {
                title: 'Import/Export',
                descriptions: [
                    `Improved formula evaluation for spreadsheet imports.`,
                    `Improved importing form results into date form fields.`,
                    `Fixed the issue where 'Set All' fields were being added as columns in spreadsheet exports.`,
                    `Errors now display in result spreadsheet when detecting duplicate specimen identifiers going into the same list.`,
                ],
            },
            {
                title: 'Trays',
                descriptions: [
                    `Significantly decreased the load time of tray locations displayed in tables, and in the location dropdown when creating or editing trays.`,
                ],
            },
            {
                title: 'Specimens',
                descriptions: [`Specimens are now allowed to have a volume of 0.`],
            },
            {
                title: 'Subjects',
                descriptions: [
                    `Added the ability to delete existing subjects along with their form results when the user has the Subject Delete role.`,
                    `The subject identifier in the subject search table can now be clicked to bring you to view that subject.`,
                ],
            },
            {
                title: 'General',
                descriptions: [`Fixed tables not updating to display new rows.`],
            },
        ],
    },
    {
        title: '2022-09 (v0.3.2)',
        releaseNotes: [
            {
                title: 'Form Result Importer',
                descriptions: [`Fixed form result importer not properly handling selectboxes with numeric values.`],
            },
        ],
    },
    {
        title: '2022-08 (v0.3.1)',
        releaseNotes: [
            {
                title: 'General',
                descriptions: [`Added "Unsaved changes" warning when attempting to navigate away from a page that has unsaved changes.`],
            },
            {
                title: 'Searches',
                descriptions: [
                    `Sorting has been enabled for a subset of search result table columns.`,
                    'Added year/month picker to forms. Added year/month picker to searches.',
                ],
            },
            {
                title: 'Forms',
                descriptions: [
                    `Added a "Set All Section" when viewing forms with a layout type of "Single & Grid Form" in grid mode. 
                    This allows you to set all rows in the grid to the same value for a given field.`,
                ],
            },
            {
                title: 'Trays',
                descriptions: [
                    `Specimens belonging to a study that a user does not have access to will be displayed in the tray view as 'HIDDEN'.`,
                    `Added links to navigate to the tray edit page from the tray grid and tray table.`,
                ],
            },
        ],
    },
    {
        title: '2022-07 (v0.2.0)',
        releaseNotes: [
            { title: 'Customize Labels', descriptions: ['Translations will now update on every software upgrade.'] },
            {
                title: 'Single Use Forms',
                descriptions: [
                    'Forms can now be set to be either single or multi-use. All existing forms will be defaulted to multi-use.',
                    'Single-use means that if a form already has a result for a certain record, you cannot create new results for that form on that record; you can only edit or delete the existing result.',
                    'Added the ability to update single-use form results within grid forms.',
                ],
            },
        ],
    },
    {
        title: '2022-07 (v0.1.0)',
        releaseNotes: [
            {
                title: 'In-App Release Notes',
                descriptions: [
                    'Added a new slide-out menu at the top right containing release notes. These notes will help keep users up to date with the latest changes.',
                    'Added a notification badge to the release notes button to notify users when there are new changes available to read.',
                ],
            },
            {
                title: 'Form Picker',
                descriptions: ['You can now select multiple forms with the form picker, so that many forms can be opened and edited simultaneously.'],
            },
            {
                title: 'Customize Labels',
                descriptions: [`Form title and field labels can now be customized.`],
            },
            {
                title: 'Form Results',
                descriptions: [
                    'The form results table will now refresh after deleting a form result.',
                    'Improved the formatting for date/time fields displayed in tables.',
                ],
            },
            {
                title: 'Searches',
                descriptions: [
                    'Specimen & subject search results are now sorted by their identifiers.',
                    'Form search results are now sorted by last modified time.',
                ],
            },
            {
                title: 'Specimen Export',
                descriptions: [`Fixed the tray column to export properly from specimen table.`],
            },
        ],
    },
];
