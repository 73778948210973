import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SlamReleaseNotesService } from './slam-release-notes.service';
import { RELEASES } from './slam-releases';

@Component({
    selector: 'slam-release-notes',
    templateUrl: './slam-release-notes.component.html',
    styleUrls: ['./slam-release-notes.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlamReleaseNotesComponent implements OnInit, OnDestroy {
    public readonly RELEASES = RELEASES.slice();

    public showSidebar = false;
    public showBadge = false;

    private destroy$ = new Subject<void>();

    constructor(private releaseNotesService: SlamReleaseNotesService, private changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.displayBadgeIfNewRelease();
        this.releaseNotesService.viewedLatestReleaseChanged$.pipe(takeUntil(this.destroy$)).subscribe(() => this.displayBadgeIfNewRelease());
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public onOpenSidebar(): void {
        this.showSidebar = true;
        this.releaseNotesService.setViewedLatestRelease();
    }

    private displayBadgeIfNewRelease(): void {
        this.showBadge = !this.releaseNotesService.hasViewedLatestRelease();
        this.changeDetectorRef.markForCheck();
    }
}
