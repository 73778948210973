import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiLink } from '@models/core/slam-api-link.model';
import { BaseEntity } from '@models/core/slam-base-entity.model';
import { HttpOptions } from '@models/core/slam-http-options.models';
import { Page } from '@models/core/slam-page.model';
import { PagedModel } from '@models/core/slam-paged-model.model';
import { SlamFormIoForm } from '@models/form-io/slam-form-io-form.model';
import { MenuItem } from 'primeng/api/menuitem';
import { Observable, Subject } from 'rxjs';
import { SlamApiService } from 'src/app/utils/slam-api.service';
import { environment } from 'src/environments/environment';
import { SlamTranslationService } from '../core/translation/slam-translation.service';
import { CRUD } from '../form-io/slam-form-io-form-constants';
import { SlamFormService } from '../form-io/slam-form.service';

@Injectable({
    providedIn: 'root',
})
export class CrudService {
    public readonly crudResultCollectionChange$ = new Subject();

    constructor(private slamApiService: SlamApiService, private formService: SlamFormService, private translationService: SlamTranslationService) {}

    public getCollection(path: string, sort: string, httpOptions: HttpOptions = {}, page?: Page): Observable<PagedModel<BaseEntity>> {
        const link: ApiLink = { href: environment.apiBaseUrl + path + '{?studyId}{&page,size,sort}' };
        const params: HttpParams = new HttpParams();
        if (!page?.sort) {
            params.append('sort', sort);
        }
        httpOptions.params = params;
        return this.slamApiService.get<PagedModel<BaseEntity>>(link, page, httpOptions);
    }

    public getFormsForMenu(): Observable<PagedModel<SlamFormIoForm>> {
        return this.formService.getFormsByFormTypeConstant(CRUD);
    }

    public menuItemsFromForms(forms: SlamFormIoForm[]): MenuItem[] {
        const menuItems: MenuItem[] = [];
        forms.forEach((form) => {
            menuItems.push({
                label: this.translationService.translateKey(form.name),
                routerLink: ['/admin/crud/' + form.constant],
            });
        });
        return menuItems;
    }
}
