import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SlamPendingChangesService } from '../slam/pending-changes/slam-pending-changes.service';
import { SlamApiService } from './slam-api.service';

@Injectable({
    providedIn: 'root',
})
export class SlamSessionService {
    public static WARN_OF_IDLE_TIMEOUT_MINUTES = 5;
    private static ONE_MINUTE_IN_MILLIS = 60000;
    private static CHECK_API = true;
    private static CHECK_USER = false;
    private static ONE_MINUTE = 1;
    private static ZERO_MINUTES = 0;

    public logoutWarning: Subject<void> = new Subject<void>();

    private lastApiInteraction = new Date().getTime();
    private lastUserInteraction = new Date().getTime();
    private timeoutMinutes = environment.keycloakIdleTimeoutMinutes - SlamSessionService.ONE_MINUTE;
    private timeoutMillis: number;
    private intervalDuration: number;

    constructor(
        private pendingChangesService: SlamPendingChangesService,
        private slamApiService: SlamApiService,
        private keycloakService: KeycloakService
    ) {
        this.timeoutMillis = this.timeoutMinutes * SlamSessionService.ONE_MINUTE_IN_MILLIS;
        this.intervalDuration = SlamSessionService.ONE_MINUTE_IN_MILLIS;
    }

    // for tests
    public setTestSettings(timeoutMillisIn: number): void {
        this.intervalDuration = 5000; // five seconds
        this.timeoutMillis = timeoutMillisIn;
    }

    public getLastApiInteraction(): number {
        return this.lastApiInteraction;
    }

    public getLastUserInteraction(): number {
        return this.lastUserInteraction;
    }

    public startSessionIdleCheck(): void {
        setInterval(() => {
            if (this.idleTimeoutWillHappenIn(SlamSessionService.ZERO_MINUTES, SlamSessionService.CHECK_USER)) {
                // We have been idle for the maximum number of minutes (or slightly more - within 0-59 seconds), logout.
                this.logout();
            } else {
                if (this.idleTimeoutWillHappenIn(SlamSessionService.ONE_MINUTE, SlamSessionService.CHECK_API)) {
                    // Without activity against the api, keycloak may time us out with its internal SSO Session Idle settings
                    // call keep alive if we're getting close to that timeout so that we can manage the idle timeout warnings
                    // and logout on our own.
                    this.keepAlive();
                }
                if (this.idleTimeoutWillHappenIn(SlamSessionService.WARN_OF_IDLE_TIMEOUT_MINUTES, SlamSessionService.CHECK_USER)) {
                    this.logoutWarning.next();
                }
            }
        }, this.intervalDuration);
    }

    public idleTimeoutWillHappenIn(minutes: number, api: boolean): boolean {
        const willHappenInMillis = minutes * SlamSessionService.ONE_MINUTE_IN_MILLIS;
        let millisPassed = new Date().getTime();
        if (api) {
            millisPassed -= this.lastApiInteraction;
        } else {
            millisPassed -= this.lastUserInteraction;
        }
        const millisRemaining = this.timeoutMillis - millisPassed;
        return millisRemaining <= willHappenInMillis;
    }

    public recordApiInteraction(): void {
        this.lastApiInteraction = new Date().getTime();
    }

    public recordUserInteraction(): void {
        this.lastUserInteraction = new Date().getTime();
    }

    public logout(): void {
        sessionStorage.clear();
        this.pendingChangesService.clearPendingChanges();
        this.keycloakService.logout(window.location.origin);
    }

    private keepAlive() {
        // Doesn't use slamApiService since slamApiService uses this service file, and that would cause a circular reference.
        this.slamApiService
            .get<boolean>({ href: environment.apiBaseUrl + '/api/v1.0/keep-alive' })
            .pipe(take(1))
            .subscribe(() => {
                // do nothing, hitting the endpoint is enough to reset our idle timeout in keycloak.
            });
    }
}
