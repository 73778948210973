<ng-container *transloco="let t">
    <div *slamFeatureFlag="'language-picker'" class="language-picker">
        <strong>{{ t('language.label') }}: </strong>
        <p-dropdown
            [options]="languages"
            [(ngModel)]="selectedLanguage"
            (onChange)="updateActiveLanguage($event.value)"
            [placeholder]="t('language.select-language')"
            appendTo="body"
        >
        </p-dropdown>
    </div>
</ng-container>
