import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
export interface EnvConfiguration {
    stage: 'prod' | 'staging' | 'dev';
    featureFlags: { [key: string]: boolean };
}

@Injectable({ providedIn: 'root' })
export class EnvConfigService {
    private readonly configUrl = 'assets/config/environment-config.json';
    private configuration$: Observable<EnvConfiguration>;

    constructor(private http: HttpClient) {
        this.configuration$ = this.http.get<EnvConfiguration>(`${this.configUrl}`).pipe(shareReplay(1));
    }

    public load(): Observable<EnvConfiguration> {
        return this.configuration$;
    }
}
