import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureFlagsService } from './feature-flags.service';

@Directive({
    selector: '[slamFeatureFlag]',
})
export class FeatureFlagDirective implements OnInit {
    private requiredFlag = '';
    private isHidden = true;

    @Input('slamFeatureFlag') set featureFlag(flag: string) {
        if (flag) {
            this.requiredFlag = flag;
            this.updateView();
        }
    }

    get featureFlag(): string {
        return this.requiredFlag;
    }

    constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private featureFlagsService: FeatureFlagsService) {}

    ngOnInit(): void {
        this.updateView();
    }

    private updateView() {
        if (this.checkValidity()) {
            if (this.isHidden) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.isHidden = false;
            }
        } else {
            this.viewContainer.clear();
            this.isHidden = true;
        }
    }

    private checkValidity() {
        return this.requiredFlag && this.featureFlagsService.isFeatureFlagEnabled(this.requiredFlag);
    }
}
