import { Injectable } from '@angular/core';
import { ApiLinks } from '@models/core/slam-api-links.model';
import { CustomTranslation } from '@models/custom-translation/slam-custom-translation.model';
import { Translation } from '@ngneat/transloco';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { SlamApiService } from 'src/app/utils/slam-api.service';

@Injectable({
    providedIn: 'root',
})
export class SlamCustomTranslationService {
    public readonly customTranslationsChange$ = new Subject<CustomTranslation>();

    private readonly CUSTOM_TRANSLATION_PATH = '/api/v1.0/custom-translations/metadata';

    private readonly _endpoints$ = new ReplaySubject<ApiLinks>(1);

    constructor(private slamApiService: SlamApiService) {
        this.retrieveEndPoints();
    }

    public endpoints(): Observable<ApiLinks> {
        return this._endpoints$.asObservable();
    }

    public getTranslation(language: string): Observable<Translation> {
        return new Observable((observer) => {
            this.endpoints()
                .pipe(take(1))
                .subscribe((endpoints) => {
                    this.slamApiService
                        .get<Translation>(endpoints.list, { language })
                        .pipe(take(1))
                        .subscribe((translation) => {
                            observer.next(translation);
                            observer.complete();
                        });
                });
        });
    }

    private retrieveEndPoints() {
        this.slamApiService
            .get<CustomTranslation>({ href: this.CUSTOM_TRANSLATION_PATH })
            .pipe(take(1))
            .subscribe((endPoints) => {
                this._endpoints$.next(endPoints._links);
            });
    }
}
