import { Injectable } from '@angular/core';
import { CollectionModel } from '@models/core/slam-collection-model.model';
import { SlamStudy } from '@models/study/slam-study.model';
import { Observable } from 'rxjs';
import { SlamApiService } from 'src/app/utils/slam-api.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class SlamStudyService {
    static subjectResourceUrl = '/api/v1.0/studies';
    static defaultStudyProjection = 'studies-projection';

    constructor(private slamApiService: SlamApiService) {}

    public getStudies(projection = SlamStudyService.defaultStudyProjection): Observable<CollectionModel<SlamStudy>> {
        const url = { href: environment.apiBaseUrl + '/api/v1.0/studies' };
        return this.slamApiService.get<CollectionModel<SlamStudy>>(url, null, {
            params: { sort: 'name,asc', projection },
        });
    }
}
