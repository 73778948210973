<ng-container *transloco="let t">
    <p-button (click)="onOpenSidebar()" styleClass="p-button-help ml-3" [pTooltip]="t('release-notes.title')" [showDelay]="500">
        <ng-template pTemplate="content">
            <em *ngIf="showBadge" class="fa fa-gift" style="font-size: 1.5rem" pBadge severity="info"></em>
            <em *ngIf="!showBadge" class="fa fa-gift" style="font-size: 1.5rem"></em>
        </ng-template>
    </p-button>
    <p-sidebar [(visible)]="showSidebar" position="right" appendTo="body" styleClass="p-sidebar-md">
        <div class="pb-5">
            <h3 class="mb-0">{{ t('release-notes.title') }}</h3>
            <div *ngFor="let release of RELEASES" class="px-1">
                <h4 class="mt-4 mb-2">{{ release.title }}</h4>
                <div class="release-wrapper">
                    <div *ngFor="let releaseNote of release.releaseNotes" class="note-wrapper mx-2">
                        <h6 class="mb-1 font-bold">{{ releaseNote.title }}</h6>
                        <ul class="mb-0 pl-4">
                            <li *ngFor="let description of releaseNote.descriptions">{{ description }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </p-sidebar>
</ng-container>
