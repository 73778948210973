import { Injectable } from '@angular/core';
import { HashMap, Translation, TranslocoService } from '@ngneat/transloco';

@Injectable({
    providedIn: 'root',
})
export class SlamTranslationService {
    constructor(private translocoService: TranslocoService) {}

    public translateNestedKeys(translation: Translation): { [key: string]: string } {
        const translatedTranslation: { [key: string]: string } = {};
        for (const key of Object.keys(translation)) {
            translatedTranslation[key] = this.translocoService.translate(key);
        }
        return translatedTranslation;
    }

    /**
     * @param potentialKey
     * @returns The transloco translated value if the potentialKey was structured like a transloco key, if not, we simply
     * return the potentialKey as is.
     */
    public translateKey(potentialKey: string | undefined, params?: HashMap): string | undefined {
        let value = potentialKey;
        // This regex is to avoid errors saying missing translations for keys that have been hard-coded as English.
        // Regex Example: common.save AND tray.manage.title
        const translocoKeyRegex = /^\S+\.\S+$/;
        if (value && translocoKeyRegex.test(value)) {
            value = this.translocoService.translate(value, params);
        }
        return value;
    }
}
