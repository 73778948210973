import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Subject, Observable, BehaviorSubject, forkJoin } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { SlamApiService } from 'src/app/utils/slam-api.service';

import { CollectionModel } from '@models/core/slam-collection-model.model';
import { Dashboard } from '@models/dashboard/slam-dashboard.model';

export enum Visibility {
    PUBLIC = 'PUBLIC',
    PRIVATE = 'PRIVATE',
}

@Injectable({
    providedIn: 'root',
})
export class DashboardService {
    public dashboardsChanged$ = new BehaviorSubject(true);

    private destroy$: Subject<void> = new Subject<void>();

    constructor(private slamApiService: SlamApiService) {}

    public destroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public get(id: number): Observable<Dashboard> {
        return this.slamApiService.get<Dashboard>({ href: SlamApiService.apiUrl + '/api/v1.0/dashboards/{id}' }, { id });
    }

    public save(dashboard: Dashboard): Observable<Dashboard> {
        return this.slamApiService.post<Dashboard>({ href: SlamApiService.apiUrl + '/api/v1.0/dashboards' }, dashboard);
    }

    public delete(id: number): Observable<void> {
        return this.slamApiService.delete({ href: SlamApiService.apiUrl + '/api/v1.0/dashboards/{id}' }, { id });
    }

    public visibilityIconClassesFor(dashboard: Dashboard): string {
        switch (dashboard.visibility) {
            case Visibility.PRIVATE:
                return 'fas fa-user-shield';
            case Visibility.PUBLIC:
                return 'fas fa-users';
            default:
                throw new Error('Not configured for unexpected Visibility status of: [' + dashboard.visibility + ']');
        }
    }

    public retrieveDashboardMenuItems(createNewLabel: string): Observable<MenuItem[]> {
        return new Observable((observer) => {
            forkJoin({
                userDashboards: this.getUserDashboards(),
                sharedDashboards: this.getSharedDashboards(),
            })
                .pipe(take(1), takeUntil(this.destroy$))
                .subscribe(
                    (joinResults) => {
                        const dashboardMenuItems: MenuItem[] = [];
                        dashboardMenuItems.push(...this.createMenuItems(joinResults.userDashboards, 'fas fa-user-shield'));
                        dashboardMenuItems.push(...this.createMenuItems(joinResults.sharedDashboards, 'fas fa-users'));
                        dashboardMenuItems.push(this.createNewMenuItem(createNewLabel));

                        observer.next(dashboardMenuItems);
                        observer.complete();
                    },
                    (error) => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    private getUserDashboards(): Observable<CollectionModel<Dashboard>> {
        return this.slamApiService.get<CollectionModel<Dashboard>>(
            { href: SlamApiService.apiUrl + '/api/v1.0/dashboards' },
            { params: { sort: 'name' } }
        );
    }

    private getSharedDashboards(): Observable<CollectionModel<Dashboard>> {
        return this.slamApiService.get<CollectionModel<Dashboard>>(
            { href: SlamApiService.apiUrl + '/api/v1.0/dashboards/public' },
            { params: { sort: 'name' } }
        );
    }

    private createMenuItems(userDashboardsCollectionModel: CollectionModel<Dashboard>, iconClasses: string): MenuItem[] {
        const menuItems: MenuItem[] = [];
        if (userDashboardsCollectionModel._embedded?.dashboards) {
            const dashboards = userDashboardsCollectionModel._embedded.dashboards;
            for (const dashboard of dashboards) {
                menuItems.push({
                    label: dashboard.name,
                    routerLink: ['/dashboard/' + dashboard.id],
                    icon: iconClasses,
                });
            }
        }
        return menuItems;
    }

    private createNewMenuItem(createNewLabel: string): MenuItem {
        return {
            label: createNewLabel,
            routerLink: ['/dashboard'],
            icon: 'fas fa-plus',
        };
    }
}
