import { Injectable } from '@angular/core';
import { QuickSearchResult } from '@models/quick-search/slam-quick-search-result.model';
import { Observable } from 'rxjs';
import { SlamApiService } from 'src/app/utils/slam-api.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class SlamQuickSearchService {
    private quickSearchUrl = '/api/v1.0/quick-search';

    constructor(private slamApiService: SlamApiService) {}

    public getQuickSearchResult(searchText: string): Observable<QuickSearchResult[]> {
        const url = { href: environment.apiBaseUrl + this.quickSearchUrl + '{?searchText}' };
        return this.slamApiService.get<QuickSearchResult[]>(url, { searchText });
    }

    public reindex(): Observable<void> {
        const url = { href: environment.apiBaseUrl + this.quickSearchUrl + '/reindex' };
        return this.slamApiService.get<void>(url);
    }
}
