import { Injectable } from '@angular/core';
import { UserInfo } from '@models/user-info/slam-user-info.model';
import { Subject } from 'rxjs';
import { SlamUserInfoService } from '../user-info/slam-user-info.service';
import { RELEASES } from './slam-releases';

@Injectable({
    providedIn: 'root',
})
export class SlamReleaseNotesService {
    public readonly viewedLatestReleaseChanged$ = new Subject<void>();

    private userInfo?: UserInfo;

    constructor(private userInfoService: SlamUserInfoService) {
        this.userInfoService.userInfoChange$.subscribe((userInfo) => {
            this.userInfo = userInfo;
            this.viewedLatestReleaseChanged$.next();
        });
    }

    public hasViewedLatestRelease(): boolean {
        // This method will get hit once before userInfo has been set, so return true
        // that time to make it so the badge doesn't flicker on and off
        return !this.userInfo || this.userInfo?.latestViewedRelease === RELEASES[RELEASES.length - 1].title;
    }

    public setViewedLatestRelease(): void {
        this.userInfoService.setLatestViewedRelease(RELEASES[RELEASES.length - 1].title);
    }
}
