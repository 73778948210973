import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import { SlamUserInfoService } from '../../user-info/slam-user-info.service';

@Component({
    selector: 'slam-language-picker',
    templateUrl: './slam-language-picker.component.html',
    styleUrls: ['./slam-language-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlamLanguagePickerComponent implements OnDestroy {
    public languages: AvailableLangs;
    public selectedLanguage: string;

    private destroy$ = new Subject<void>();

    constructor(
        private translocoService: TranslocoService,
        private userInfoService: SlamUserInfoService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this.languages = translocoService.getAvailableLangs();

        this.userInfoService.userInfoChange$.pipe(takeUntil(this.destroy$)).subscribe((userInfo) => {
            if (!!userInfo.preferredLanguage && userInfo.preferredLanguage !== this.translocoService.getActiveLang()) {
                this.translocoService.setActiveLang(userInfo.preferredLanguage);
            }
            this.selectedLanguage = this.translocoService.getActiveLang();
            this.changeDetectorRef.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public updateActiveLanguage(language: string): void {
        this.translocoService.setActiveLang(language);
        this.userInfoService.setPreferredLanguage(language);
        this.changeDetectorRef.markForCheck();
    }
}
