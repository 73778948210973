import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SlamLoadingService {
    public isLoading = new Subject<boolean>();

    private loadRequests = [];

    constructor() {}

    show(): void {
        this.loadRequests.push(true);
        this.isLoading.next(true);
    }

    hide(): void {
        this.loadRequests.pop();
        setTimeout(() => {
            if (!this.loadRequests.length) {
                this.isLoading.next(false);
            }
        });
    }
}
