// @ts-nocheck

import { NONE_TYPE } from '@angular/compiler';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { KeycloakService } from 'keycloak-angular';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FeatureFlagsService } from './slam/core/feature-flags.service';
import { ROLE_ADMIN, ROLE_SPECIMEN_EXPORT } from './slam/core/role-constants';

import { CrudService } from './slam/crud/crud.service';
import { DashboardService } from './slam/dashboard/slam-dashboard.service';

@Component({
    selector: 'app-menu',
    template: `
        <div class="menu">
            <ul class="layout-menu">
                <li app-menuitem *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true"></li>
            </ul>
        </div>
    `
})
export class AppMenuComponent implements OnInit, OnDestroy {
    model: MenuItem[];

    private adminMenuKey: string;
    private destroy$ = new Subject<void>();

    constructor(
        private translocoService: TranslocoService,
        private crudService: CrudService,
        private dashboardService: DashboardService,
        private keycloakService: KeycloakService,
        private featureFlagsService: FeatureFlagsService
    ) {}

    ngOnInit(): void {
        // TODO: SLAM-968 - refactor ngOnInit customizations to a service 

        // This will make sure the currently selected language is loaded so it can be used for the menu.
        this.translocoService
            .selectTranslation()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.model = [
                    {
                        label: this.translocoService.translate('dashboard.title'),
                        icon: 'fas fa-tachometer-alt',
                        routerLink: ['/dashboard*'],
                        items: [],
                    },
                    {
                        label: this.translocoService.translate('specimen.title'),
                        icon: 'fas fa-fw fa-disease',
                        routerLink: ['/specimen*'],
                        items: [
                            {
                                label: this.translocoService.translate('specimen.title'),
                                routerLink: ['/specimen/form'],
                            },
                            {
                                label: this.translocoService.translate('specimen.search.title'),
                                routerLink: ['/specimen/search'],
                            },
                            {
                                label: this.translocoService.translate('specimen-list.title'),
                                routerLink: ['/specimen-list/form'],
                            },
                            {
                                label: this.translocoService.translate('specimen.import.title'),
                                routerLink: ['/specimen/import'],
                            },
                            {
                                label: this.translocoService.translate('specimen.result-import.title'),
                                routerLink: ['/specimen/result-import'],
                            },
                        ],
                    },
                    {
                        label: this.translocoService.translate('subject.title'),
                        icon: 'fas fa-fw fa-user',
                        routerLink: ['/subject*'],
                        items: [
                            {
                                label: this.translocoService.translate('subject.title'),
                                routerLink: ['/subject/form'],
                            },
                            {
                                label: this.translocoService.translate('subject.search.title'),
                                routerLink: ['/subject/search'],
                            },
                            {
                                label: this.translocoService.translate('subject.import.title'),
                                routerLink: ['/subject/import'],
                            },
                        ],
                    },
                    {
                        label: this.translocoService.translate('tray.title'),
                        icon: 'fas fa-fw fa-th',
                        routerLink: ['/trays*'],
                        items: [
                            {
                                label: this.translocoService.translate('tray.manage.title'),
                                routerLink: ['/trays'],
                            },
                            {
                                label: this.translocoService.translate('tray.title'),
                                routerLink: ['/trays/crud/TRAY'],
                            },
                            {
                                label: this.translocoService.translate('tray-type.title'),
                                routerLink: ['/trays/crud/TRAY_TYPE'],
                            },
                            {
                                label: this.translocoService.translate('tray.import.title'),
                                routerLink: ['/trays/import'],
                            },
                        ],
                    },
                    {
                        label: this.translocoService.translate('form-io.form-instance.search.title'),
                        icon: 'fas fa-fw fa-clipboard-list',
                        routerLink: ['/form-instance/search'],
                    },
                    {
                        label: this.translocoService.translate('form-io.form-result.search.title'),
                        icon: 'fas fa-fw fa-clipboard-list',
                        routerLink: ['/form-result/search'],
                    },
                ];

                if (this.featureFlagsService.isFeatureFlagEnabled('data-dictionary')) {
                    this.model.push({
                        label: this.translocoService.translate('data-dictionary.title'),
                        icon: 'fas fa-book-open',
                        routerLink: ['/data-dictionary'],
                    });
                }

                this.adminMenuKey = this.translocoService.translate('crud.title');

                // Avoid duplicating the Admin menu by only replacing the items
                this.model.push({
                    label: this.adminMenuKey,
                    icon: 'fas fa-fw fa-wrench',
                    routerLink: ['/admin/*'],
                    items: [],
                });

                this.crudService
                    .getFormsForMenu()
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((pagedModel) => {
                        if (pagedModel._embedded?.formIoForms) {
                            const forms = pagedModel._embedded.formIoForms;
                            const menuItems = this.crudService.menuItemsFromForms(forms);
                            menuItems.unshift({
                                label: this.translocoService.translate('custom-translation.page-title'),
                                routerLink: ['/admin/custom-translation'],
                            });
                            menuItems.unshift({
                                label: this.translocoService.translate('form-io.auditor.title'),
                                routerLink: ['/admin/form-auditor'],
                            });
                            menuItems.unshift({
                                label: this.translocoService.translate('form-io.builder.title'),
                                routerLink: ['/admin/form-builder'],
                                routerLinkActiveOptions: { exact: false },
                            });

                            if (this.keycloakService.isUserInRole(ROLE_ADMIN)) {
                                menuItems.push({
                                    label: this.translocoService.translate('admin.tools.title'),
                                    routerLink: ['/admin/tools'],
                                    routerLinkActiveOptions: { exact: false },
                                });
                            }

                            if (this.keycloakService.isUserInRole(ROLE_SPECIMEN_EXPORT)) {
                                menuItems.push({
                                    label: this.translocoService.translate('specimen.export.title'),
                                    routerLink: ['/specimen/export'],
                                    routerLinkActiveOptions: { exact: false },
                                });
                            }


                            this.model[this.model.length - 1].items = menuItems;
                        }
                    });

                this.dashboardService.dashboardsChanged$.pipe(takeUntil(this.destroy$)).subscribe(() => {
                    this.dashboardService
                        .retrieveDashboardMenuItems(this.translocoService.translate('dashboard.create-new'))
                        .pipe(takeUntil(this.destroy$))
                        .subscribe((dashboardMenuItems) => {
                            this.model[0].items = dashboardMenuItems;
                        });
                });
            });
    }

    ngOnDestroy(): void {
        this.dashboardService.destroy();
        this.destroy$.next();
        this.destroy$.complete();
    }
}
