import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'slamTrayHeader',
})
export class SlamTrayHeaderPipe implements PipeTransform {
    static readonly INDEX_TO_ALPHA = 'indexToAlpha';
    static readonly ALPHA_TO_INDEX = 'alphaToIndex';
    static readonly NUMBER_TO_INDEX = 'numberToIndex';
    static readonly INDEX_TO_NUMBER = 'indexToNumber';

    transform(value: number | string, type: string): any {
        if (SlamTrayHeaderPipe.INDEX_TO_ALPHA === type) {
            return this.convertIndexToLetter(+value);
        } else if (SlamTrayHeaderPipe.ALPHA_TO_INDEX === type) {
            return this.convertLettersToIndex(value.toString());
        } else if (SlamTrayHeaderPipe.NUMBER_TO_INDEX === type) {
            return this.convertNumberToIndex(+value);
        } else if (SlamTrayHeaderPipe.INDEX_TO_NUMBER === type) {
            return this.convertIndexToNumber(+value);
        }
        return value;
    }

    public convertLettersToIndex(letters: string): number {
        if (letters) {
            // convert A to 1, Z to 26, AA to 27
            let n = 0;
            letters = letters.toUpperCase();
            for (let p = 0; p < letters.length; p++) {
                n = letters.charCodeAt(p) - 64 + n * 26;
            }

            return this.convertNumberToIndex(n);
        } else {
            return null;
        }
    }

    public convertNumberToIndex(num: number): number {
        if (num) {
            return num - 1;
        } else {
            return null;
        }
    }

    public convertIndexToNumber(num: number): number {
        return num + 1;
    }

    public convertIndexToLetter(num: number): string {
        const value = this.convertIndexToNumber(num);
        return this.convertNumberToLetters(value);
    }

    private convertNumberToLetters(num: number): string {
        const mod = num % 26;
        // eslint-disable-next-line no-bitwise
        let pow = (num / 26) | 0;
        const out = mod ? String.fromCharCode(64 + mod) : (--pow, 'Z');
        return pow ? this.convertNumberToLetters(pow) + out : out;
    }
}
