import { Injectable } from '@angular/core';
import { ApiLink } from '@models/core/slam-api-link.model';
import { SlamApiService } from 'src/app/utils/slam-api.service';
import { environment } from 'src/environments/environment';
import { SlamTrayHeaderPipe } from '../../tray/slam-tray-header.pipe';

@Injectable({
    providedIn: 'root',
})
export class SlamFormIoConfigService {
    constructor(private apiService: SlamApiService) {}

    public configureGlobalMethods(): void {
        (window as any).slam = (window as any).slam || {};
        (window as any).slam.formio = (window as any).slam.formio || {};
        (window as any).slam.formio.getSequenceValue = this.getSequenceValue.bind(this);
        const pipe: SlamTrayHeaderPipe = new SlamTrayHeaderPipe();
        (window as any).slam.formio.rowPositionForDisplay = pipe.convertIndexToLetter.bind(pipe);
        (window as any).slam.formio.columnPositionForDisplay = pipe.convertIndexToNumber.bind(pipe);
    }

    public getSequenceValue(sequenceName: string): Promise<string> {
        const apiLink: ApiLink = { href: environment.apiBaseUrl + '/api/v1.0/identifier-sequences/sequence-value?sequenceName=' + sequenceName };
        return this.apiService.get<string>(apiLink).toPromise();
    }

    public destroyGlobalFunctions(): void {
        (window as any).slam.formio.getSequenceValue = null;
        (window as any).slam.formio.rowPositionForDisplay = null;
        (window as any).slam.formio.columnPositionForDisplay = null;
    }
}
