import { Injectable } from '@angular/core';
import { EnvConfigService } from 'src/app/env-config.service';

@Injectable({
    providedIn: 'root',
})
export class FeatureFlagsService {
    private featureFlags?: { [key: string]: boolean };

    constructor(private envConfigService: EnvConfigService) {
        this.envConfigService.load().subscribe((envConfigIn) => {
            this.featureFlags = envConfigIn.featureFlags;
        });
    }

    public isFeatureFlagEnabled(flag: string): boolean {
        return !!this.featureFlags && this.featureFlags[flag];
    }
}
