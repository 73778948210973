// @ts-nocheck

import {Component, AfterViewInit, OnDestroy, ViewChild, Renderer2, OnInit} from '@angular/core';
import {trigger, state, style, transition, animate} from '@angular/animations';
import { PrimeNGConfig } from 'primeng/api';
import { AppComponent } from './app.component';
import { KeycloakService } from 'keycloak-angular';
import { SlamLoadingService } from './slam-loading.service';
import { SlamSessionService } from './utils/session.service';
import { SlamApiService } from './utils/slam-api.service';
import { Subject, takeUntil } from 'rxjs';
import { ApiLink } from '@models/core/slam-api-link.model';
import { environment } from 'src/environments/environment';
import { VERSION } from '../environments/version';
import { SlamFormIoConfigService } from './slam/form-io/config/slam-form-io-config.service';
import { findImportLikeNodes } from 'tsutils';

const fadeInOut =
    trigger('fadeInOut', [
        transition(':enter', [
            style({opacity: 0}),
            animate('500ms ease-in')
        ]),
        transition(':leave', [
            animate('500ms ease-in', style({opacity: 0}))
        ])
    ]);

@Component({
    selector: 'app-main',
    templateUrl: './app.main.component.html',
    animations: [
        trigger('submenu', [
            state('hidden', style({
                height: '0px'
            })),
            state('visible', style({
                height: '*'
            })),
            transition('visible => hidden', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hidden => visible', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ]),
        fadeInOut
    ]
})
export class AppMainComponent implements AfterViewInit, OnDestroy, OnInit {

    public menuInactiveDesktop: boolean;

    public menuActiveMobile: boolean;

    public profileActive: boolean;

    public topMenuActive: boolean;

    public topMenuLeaving: boolean;

    documentClickListener: () => void;

    menuClick: boolean;

    topMenuButtonClick: boolean;

    public isLoading: Subject<boolean> = this.slamLoadingService.isLoading;

    private destroy$ = new Subject<void>();

    configActive: boolean;

    configClick: boolean;

    public userName: string;

    public displayModal = false;

    constructor(public renderer: Renderer2, 
        private primengConfig: PrimeNGConfig, 
        public app: AppComponent, 
        private keycloakService: KeycloakService,
        private slamSessionService: SlamSessionService,
        private slamLoadingService: SlamLoadingService,
        private apiService: SlamApiService,
        private formIoConfigService: SlamFormIoConfigService,
        ) {
            /* eslint-disable-next-line no-console, @typescript-eslint/restrict-template-expressions */
            console.log(`version=${VERSION.version}, git-tag=${VERSION.tag}, git-hash=${VERSION.hash}`);
            
            formIoConfigService.configureGlobalMethods();
        }

    ngOnInit() {
        this.keycloakService.getKeycloakInstance()?.loadUserProfile().then((user) => {
            this.userName = user.username;
        });

        this.renderer.listen('document', 'click', () => {
            this.slamSessionService.recordUserInteraction();
        });

        this.renderer.listen('document', 'keydown', () => {
            this.slamSessionService.recordUserInteraction();
        });

        this.slamSessionService.logoutWarning
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.displayModal = true);

        this.slamSessionService.startSessionIdleCheck();

        this.primengConfig.ripple = true;
    }

    ngAfterViewInit() {
        // hides the overlay menu and top menu if outside is clicked
        this.documentClickListener = this.renderer.listen('body', 'click', (event) => {
            if (!this.isDesktop()) {
                if (!this.menuClick) {
                    this.menuActiveMobile = false;
                }

                if (!this.topMenuButtonClick) {
                    this.hideTopMenu();
                }
            }
            else {
                if (!this.menuClick && this.isOVerlay()) {
                    this.menuInactiveDesktop = true;
                }
            }

            if (this.configActive && !this.configClick) {
                this.configActive = false;
            }

            this.configClick = false;
            this.menuClick = false;
            this.topMenuButtonClick = false;
        });
    }

    toggleMenu(event: Event) {
        this.menuClick = true;
        if (this.isDesktop()) {
            this.menuInactiveDesktop = !this.menuInactiveDesktop;
            if (this.menuInactiveDesktop) {
                this.menuActiveMobile = false;
            }
        } else {
            this.menuActiveMobile = !this.menuActiveMobile;
            if (this.menuActiveMobile) {
                this.menuInactiveDesktop = false;
            }
        }

        if (this.topMenuActive) {
            this.hideTopMenu();
        }

        event.preventDefault();
    }

    toggleProfile(event: Event) {
        this.profileActive = !this.profileActive;
        event.preventDefault();
    }

    toggleTopMenu(event: Event) {
        this.topMenuButtonClick = true;
        this.menuActiveMobile = false;

        if (this.topMenuActive) {
            this.hideTopMenu();
        } else {
            this.topMenuActive = true;
        }

        event.preventDefault();
    }

    hideTopMenu() {
        this.topMenuLeaving = true;
        setTimeout(() => {
            this.topMenuActive = false;
            this.topMenuLeaving = false;
        }, 500);
    }

    onMenuClick() {
        this.menuClick = true;
    }

    onRippleChange(event) {
        this.app.ripple = event.checked;
        this.primengConfig.ripple = event.checked;
    }

    onConfigClick(event) {
        this.configClick = true;
    }

    isStatic() {
        return this.app.menuMode === 'static';
    }

    isOVerlay() {
        return this.app.menuMode === 'overlay';
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    onSearchClick() {
        this.topMenuButtonClick = true;
    }

    ngOnDestroy() {
        if (this.documentClickListener) {
            this.documentClickListener();
        }
        this.destroy$.next();
        this.destroy$.complete();
        
        this.formIoConfigService.destroyGlobalFunctions();
    }

    public logout(): void {
        this.slamSessionService.logout()
    }

    public getSequenceValue(sequenceName: string): Promise<string> {
        const apiLink: ApiLink = {href: environment.apiBaseUrl + '/api/v1.0/identifier-sequences/sequence-value?sequenceName=' + sequenceName}
        return this.apiService.get<string>(apiLink).toPromise();
    }

}
